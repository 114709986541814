<template>
  <div class="mt-10">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <img :src="require('../assets/check.svg')" class="w-16" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <div class="col text-center">
              <h2 class="mt-4">Partner Application Completed </h2>

              <p>Thank you for your interest in our partner program.</p>
              <p>We will review your application and revert soon.</p>
              <p>Please, keep checking your email for our response.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <router-link to="/"><<< Back to Login</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignCompleted',
  components: {},
  data() {
    return {

    }
  },
  methods: {

  },
  async mounted() {

  }
};
</script>
